/* src/pages/SignupPage.module.css */
.signupPage {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-image: url('../../assets/transparent_logo.svg'); /* Adjust the path as necessary */
  background-size: 40% 40%; /* Cover the entire background */
  background-position: top; /* Center the background image */
  background-repeat: no-repeat;
  background-color: rgb(252, 242, 244);
}

.mainContent {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  background-color: #f8f9fa;
  text-align: center;
}
