.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  background-color: #f8f9fa;
  text-align: center;
  position: relative;
  z-index: 1000;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%; /* Fills remaining space in mainContent */
  width: 100%; /* Fills remaining space horizontally */
  text-align: center; /* Center text content */
  padding: 140px; /* Consistent padding with mainContent */
}

.mainContent {
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-left: 200px; /* adjust this value as needed */
  margin-right: 200px;
}

.heading {
  text-align: center; /* Center text horizontally */
  padding: 20px; /* Add some padding around the text */
  background-color: #ffffff; /* Light grey background for visibility */
}

.heading h1 {
  font-weight: bold; /* Make the font bold */
  font-size: 24px; /* Set the size of the font */
  color: #333; /* Dark grey color for the text */
  margin: 0; /* Remove default margin */
  font-family: 'Arial', sans-serif; /* Stylish, modern font */
}

.content {
  display: flex;
  flex-wrap: wrap; /* Key change: Allow wrapping for responsive layouts */
  justify-content: space-between;
}

.filter {
  flex: 1;
  margin-right: 20px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  margin-top: 120px; /* Remove margin-top as flexbox will handle positioning */
}

/* .tinyBanner {
  height: 50px;
} */

.filter > div {
  margin-bottom: 20px; /* Add spacing between TinyBanner and List */
}

.featureRequests {
  flex: 3;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
  margin-top: 0; /* Remove margin-top for consistent top alignment */
}

.modalWrapper {
  position: absolute;
  bottom: 70px; /* Adjust the distance from the bottom */
  right: 20px; /* Adjust the distance from the right */
  z-index: 1000; /* Ensure the modal is above other content */
}

.paymentButtonWrapper {
  position: absolute;
  bottom: 70px; /* Adjust the distance from the bottom */
  left: 20px; /* Adjust the distance from the left */
  z-index: 1000; /* Ensure the button is above other content */
}

.footer {
  position: fixed; /* Fix the position */
  bottom: 0; /* Align to the bottom of the viewport */
  left: 0; /* Align to the left edge */
  width: 100%; /* Occupy full width of the viewport */
  background-color: #f8f9fa;
  text-align: center;
  z-index: 1000; /* Ensure it's above other content */
}
